// Fork from @analytics/intercom@1.0.2 /lib/analytics-plugin-intercom.browser.es.js that delays loading of Intercom
import '@analytics/type-utils';

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(source, true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(source).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

/* global analytics */
var config = {
  /* Your intercom app id */
  appId: null,
  disableAnonymousTraffic: false,

  /* Customize left or right position of messenger */
  alignment: undefined,

  /* Customize horizontal padding */
  horizontalPadding: undefined,

  /* Customize vertical padding */
  verticalPadding: undefined,

  /* Css selector of the custom launcher */
  customLauncherSelector: undefined
  /**
   * intercom analytics plugin
   * @link https://getanalytics.io/plugins/intercom/
   * @link https://developers.intercom.com/installing-intercom/docs/intercom-javascript
   * @param {object}  pluginConfig - Plugin settings
   * @param {string}  pluginConfig.appId - Your intercom app id
   * @param {boolean} [pluginConfig.disableAnonymousTraffic] - Disable loading intercom for anonymous visitors
   * @param {string}  [pluginConfig.alignment] - Customize left or right position of messenger
   * @param {number}  [pluginConfig.horizontalPadding] - Customize horizontal padding
   * @param {number}  [pluginConfig.verticalPadding] - Customize vertical padding
   * @param {string}  [pluginConfig.customLauncherSelector] - Css selector of the custom launcher see https://www.intercom.com/help/en/articles/2894-customize-the-intercom-messenger-technical for additional info
   * @return {object} Analytics plugin
   * @example
   *
   * intercomPlugin({
   *   appId: '123-xyz'
   * })
   */

};

function intercomPlugin$1() {
  var pluginConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    name: "intercom",
    config: _objectSpread2(_objectSpread2({}, config), pluginConfig),

    /* Custom methods TODO: shutdown? hide show */
    methods: {
      startTour: function startTour(tourId) {
        var intercom = window.Intercom;
        if (typeof intercom === "undefined") return;
        intercom("startTour", tourId);
      },
      shutdown: function shutdown() {
        var intercom = window.Intercom;
        if (typeof intercom === "undefined") return;
        intercom("shutdown");
      },
      hide: function hide() {
        var intercom = window.Intercom;
        if (typeof intercom === "undefined") return;
        intercom("hide");
      },
      show: function show() {
        var intercom = window.Intercom;
        if (typeof intercom === "undefined") return;
        intercom("show");
      },
      showMessages: function showMessages() {
        var intercom = window.Intercom;
        if (typeof intercom === "undefined") return;
        intercom("showMessages");
      },
      showNewMessage: function showNewMessage() {
        var intercom = window.Intercom;
        if (typeof intercom === "undefined") return;
        intercom("showNewMessage");
      },
      onShow: function onShow(callback) {
        var intercom = window.Intercom;
        if (typeof intercom === "undefined") return;
        intercom("onShow", callback);
      },
      onUnreadCountChange: function onUnreadCountChange(callback) {
        var intercom = window.Intercom;
        if (typeof intercom === "undefined") return;
        intercom("onUnreadCountChange", callback);
      }
    },
    bootstrap: function bootstrap(_ref) {
      var config = _ref.config,
          instance = _ref.instance;

      /* Load intercom script after userId exists */
      if (config.disableAnonymousTraffic && !instance.user("userId")) {
        instance.once("identifyStart", function (_ref2) {
          var plugins = _ref2.plugins;
          var self = plugins["intercom"];

          if (!self.loaded()) {
            instance.loadPlugin("intercom");
          }
        });
      }
    },

    /* Load intercom widget on page */
    initialize: function initialize(_ref3) {
      var config = _ref3.config,
          instance = _ref3.instance,
          payload = _ref3.payload;
      // console.log('initialize event intercom', payload)
      var disableAnonymousTraffic = config.disableAnonymousTraffic,
          appId = config.appId,
          alignment = config.alignment,
          horizontalPadding = config.horizontalPadding,
          verticalPadding = config.verticalPadding,
          customLauncherSelector = config.customLauncherSelector;

      if (!appId) {
        throw new Error("No intercom appId");
      }
      /* Disable intercom.com if user is not yet identified. Save on Monthly MTU bill $$$ */


      var userID = instance.user("userId");

      if (!userID && disableAnonymousTraffic) {
        return false;
      }
      /* eslint-disable */


      (function () {
        var w = window;
        var ic = w.Intercom;

        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          var d = document;

          var i = function i() {
            i.c(arguments);
          };

          i.q = [];

          i.c = function (args) {
            i.q.push(args);
          };

          w.Intercom = i;

          var l = function l() {
            if(window._intercomDelayedLoadComplete) {
              return;
            }
            window._intercomDelayedLoadComplete = true;
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/" + appId;
            var x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          };

          function delayLoad() {
            window._intercomDelayedLoadComplete = false;
            ['click', 'scroll', 'mousemove', 'touchstart'].forEach(function(e) {
              window.addEventListener(e, l, { once: true });
            });
            setTimeout(l, 5000);
          }

          if (document.readyState === "complete") {
            delayLoad();
          } else if (w.attachEvent) {
            w.attachEvent("onload", delayLoad);
          } else {
            w.addEventListener("load", delayLoad, false);
          }
        }
      })();
      /* eslint-enable */


      window.intercomSettings = {
        app_id: appId,
        alignment: alignment,
        horizontal_padding: horizontalPadding,
        vertical_padding: verticalPadding,
        custom_launcher_selector: customLauncherSelector
      };
    },

    /* Trigger intercom page view */
    page: function page(_ref4) {
      var payload = _ref4.payload,
          config = _ref4.config;
      // console.log('page event intercom', payload)
      var intercom = window.Intercom;
      if (typeof intercom === "undefined") return;
      intercom("update");
    },

    /* Track intercom event */
    track: function track(_ref5) {
      var payload = _ref5.payload,
          config = _ref5.config;
      // console.log('track event intercom', payload)
      var intercom = window.Intercom;
      if (typeof intercom === "undefined") return;
      intercom("trackEvent", payload.event, payload.properties);
    },

    /* Identify Segment user */
    identify: function identify(_ref6) {
      var payload = _ref6.payload,
          config = _ref6.config;
      var intercom = window.Intercom;
      if (typeof intercom === "undefined") return;
      var userId = payload.userId,
          traits = payload.traits,
          options = payload.options;

      if (typeof userId === "string") {
        window.Intercom("boot", _objectSpread2({
          app_id: config.appId,
          user_id: userId
        }, traits));
      }
    },

    /* Remove intercom cookies on analytics.reset */
    reset: function reset() {
      var intercom = window.Intercom;
      if (typeof intercom === "undefined") return;
      intercom("shutdown");
    },

    /* Sync id when ready */
    ready: function ready(_ref7) {
      var instance = _ref7.instance,
          config = _ref7.config;
      var intercom = window.Intercom;
      if (!config.syncAnonymousId || typeof intercom === "undefined") return;
      var intercomUser = intercom("getVisitorId");

      if (intercomUser) {
        var intercomAnonId = intercom("getVisitorId");
        var analyticsAnonId = instance.user("anonymousId"); // If has intercom anonymous ID && doesnt match analytics anon id, update

        if (intercomAnonId && intercomAnonId !== analyticsAnonId) {
          instance.setAnonymousId(intercomAnonId);
        }
      }
    },

    /* Check if intercom loaded */
    loaded: function loaded() {
      return window.Intercom;
    }
  };
}

/* This module will shake out unused code + work in browser and node 🎉 */

var index = intercomPlugin$1;

export default index;
