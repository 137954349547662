import { useEffect, useRef, useState } from "react";
import { throttle } from "lodash";


export type Orientation = {
  event: DeviceOrientationEvent | null;
  alpha: number | null;
  beta: number | null;
  gamma: number | null;
  calculated: 'portrait' | 'landscape-left' | 'landscape-right' | 'unknown';
}


type IOSDeviceOrientationEvent = DeviceOrientationEvent & {
  requestPermission: () => Promise<PermissionState>;
};

function isIOSDeviceOrientationEvent(event: DeviceOrientationEvent): event is IOSDeviceOrientationEvent {
  return 'requestPermission' in event && typeof event['requestPermission'] === 'function';
}


export function useOrientation(onlyCalculated: boolean = true): Orientation | null {

  const [orientation, setOrientation] = useState<Orientation | null>(null);
  // used in callback to prevent unnecessary re-renders when onlyCalculated is true
  const calculatedRef = useRef<Orientation['calculated']>('unknown');

  useEffect(() => {
    function handleOrientation(event: DeviceOrientationEvent) {
      const beta = event.beta || 0;
      const gamma = event.gamma || 0;

      let estimatedOrientation: Orientation['calculated'] = "unknown";

      if (Math.abs(gamma) < 40 && Math.abs(beta) > 50) {
        // Gamma near 0, the device is upright (portrait mode)
        estimatedOrientation = "portrait";
      } else if (Math.abs(beta) < 40) {
        // Gamma near ±90, the device is sideways (landscape mode)
        if (gamma > 50) {
          estimatedOrientation = "landscape-right";
        } else if (gamma < -50) {
          estimatedOrientation = "landscape-left";
        }
      }

      if (!onlyCalculated || calculatedRef.current !== estimatedOrientation) {
        setOrientation({
          event,
          alpha: event.alpha,
          beta: event.beta,
          gamma: event.gamma,
          calculated: estimatedOrientation
        });
      }
    }
    const throttledOrientation = throttle(handleOrientation, 250);


    const doe = window.DeviceOrientationEvent as unknown as DeviceOrientationEvent;
    // console.log('orientation 1', doe)
    if (isIOSDeviceOrientationEvent(doe)) {
      // console.log('orientation 2', doe)
      doe.requestPermission().then(permissionState => {
        // console.log('orientation 3', doe)

        // console.log('DeviceOrientationEvent permission state:', permissionState);
        if (permissionState === 'granted') {
          // console.log('DeviceOrientationEvent permission granted');
          window.addEventListener("deviceorientation", throttledOrientation);
        }
      });
    } else {
      window.addEventListener("deviceorientation", throttledOrientation);
    }

    return () => {
      window.removeEventListener("deviceorientation", throttledOrientation);
    };
  }, [onlyCalculated]);

  return orientation;
}
