import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { Capacitor } from '@capacitor/core';

import { intercom } from '../analytics';


export const IntercomContext = createContext();

export function useIntercomContext() {
  return useContext(IntercomContext);
}

export const IntercomProvider = ({ children }) => {
  if (Capacitor.isNativePlatform() && intercom) {
    intercom.setBottomPadding({ value: '32' });
  }
  return <IntercomContext.Provider value={intercom}>{children}</IntercomContext.Provider>;
}

IntercomProvider.propTypes = {
  children: PropTypes.node,
};

