import { gql } from '@apollo/client';
import { CanonicalFragment } from './canonicalFragment';
import { ContactFragment } from './contactFragment';
import { ListFragment } from './listFragment';
import { ShareFragment } from './shareFragment';


export const GET_PROFILE = gql`
  query GetProfile($id: ID, $username: String)  {
    profile(id: $id, username: $username) {
      _id
      contact {
        ...ContactFragment
        createdAt
        bio
        bioLink
      }
      follows {
        _id
        ...ContactFragment
      }
      leads {
        _id
        ...ContactFragment
      }
      stats {
        leads
        follows
        shares
        comments
        commentsOnShares
        reactions
        reactionsOnShares
        collectionsPrivate
        collectionsPublic
        followedCollections
        leadsCollections
      }
    }
  }
  ${ContactFragment}
`;


export const GET_PROFILE_LISTS = gql`
  query GetProfileLists($id: ID, $username: String)  {
    profile(id: $id, username: $username) {
      _id
      lists {
        ...ListFragment
        items {
          createdAt
          share {
            _id
          }
        }
      }
    }
  }
  ${ListFragment}
`;


export const GET_PROFILE_POSTS = gql`
  query GetProfilePosts($id: ID, $username: String)  {
    profile(id: $id, username: $username) {
      _id
      posts {
        ...ShareFragment
        canonicalContent {
          ...CanonicalFragment
        }
      }
    }
  }
  ${ShareFragment}
  ${CanonicalFragment}
`;


export const FOLLOW_CONTACT = gql`
  mutation FollowContact($contactId: ID!, $follow: Boolean!) {
    followContact(contactId: $contactId, follow: $follow) {
      _id
      leader {
        _id
      }
    }
  }
`;


export const SEARCH_CONTACT = gql`
  query SearchContact($input: SearchContactInput!) {
    searchContact(input: $input) {
      ...ContactFragment
    }
  }
  ${ContactFragment}
`;


export const PUBLIC_SHARES = gql`
  query PublicShares($userId: ID!, $limit: Int) {
    profile(id: $userId) {
      _id
      posts(limit: $limit) {
        ...ShareFragment
        canonicalContent {
          ...CanonicalFragment
        }
      }
    }
  }
  ${ShareFragment}
  ${CanonicalFragment}
`;
